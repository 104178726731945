import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Box, CircularProgress, Divider, Link, makeStyles, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import classNames from "classnames";
import Button from "../../components/ui/buttons/Button";
import TextField from "../../components/ui/TextField";
import useSharedStyles from "../../components/useSharedStyles";
import { useAppleId } from "../../context/AppleIDProvider";
import { useGoogleLogin } from '@react-oauth/google';
import endpoints from "../../endpoints";
import { useFormik } from "formik";
import useDialogState from "../../hooks/useDialogState";
import { justFetch } from "../../mutations/mutate";
import React, { useEffect } from "react";
import { useState } from "react";
import { mutate } from "swr";
import ISession from "../../types/ISession";
import ForgotPasswordDialog from "../../components/dialogs/login/ForgotPasswordDialog";
import LoginTroubleDialog from "../../components/dialogs/login/LoginTroubleDialog";
import PageContainer from "components/ui/PageContainer";
import queryString from 'query-string';
import { IUserProfile } from "types/IUserProfile";
import { motion } from "framer-motion";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { setSessionTokenCookie } from "./loginUtils";
import { CssButton, CssTextField, useUniversalLoginStyles } from "./UniversalLogin";
import { useAlert } from "context/AlertProvider";

const import_student_code = queryString.parse(window.location.search)?.import_student_code;

export const useLoginStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  contentCentered: {
    width: '350px !important'
  },
  loginButton: {
    justifyContent: 'center',
    width: '100% !important',
    height: '40px !important',
    borderRadius: '5px !important',
  },
  appleButton: {
    justifyContent: 'center',
    width: '100% !important',
    height: '40px !important',
    borderRadius: '5px !important',
  },
  googleButton: {
    justifyContent: 'center',
    width: '100% !important',
    height: '40px !important',
    borderRadius: '5px !important',
    backgroundColor: 'white',
    borderColor: 'black',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  loginInput: {
    backgroundColor: 'white'
  }
}));

interface LoginInputProps {
  mode?: 'home' | 'school' | 'teacher';
  handleRedirectAfterLogin: (session: ISession) => void;
  inApp?: boolean;
  setLoginMode?: React.Dispatch<React.SetStateAction<'home' | 'school' | 'teacher'>>
  setShowLoginInput?: React.Dispatch<React.SetStateAction<boolean>>;
}

let appleLoggedIn = false;

const LoginInput: React.VFC<LoginInputProps> = ({ mode, handleRedirectAfterLogin, setLoginMode, setShowLoginInput, inApp = false }) => {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [submitError, setSubmitError] = useState<string | false>(false);
  const [cleverSubmitting, setCleverSubmitting] = useState(false);
  const [cleverSuccess, setCleverSuccess] = useState(false);
  const forgotPasswordDialogState = useDialogState();
  const loginTroubleDialogState = useDialogState();
  const alert = useAlert();

  const theme = useTheme();
  const loginClasses = useLoginStyles();
  const uLoginClasses = useUniversalLoginStyles();
  const sharedClasses = useSharedStyles();

  const form = useFormik({
    initialValues: {
      username: '',
      password: '',
      import_student_code: import_student_code
    },
    validate: values => {
      if (!values.username || !values.password) {
        return {
          username: !values.username ? 'Please enter your email address' : null,
          password: !values.password ? 'Please enter your password' : null,
        }
      }

      return undefined;
    },
    onSubmit: values => justFetch(endpoints.login, 'POST', {
      username: values.username.trim(),
      password: values.password,
      import_student_code: import_student_code
    })
      .then(handleKodableLoginResponse)
      .catch(handleUnexpectedKodableLoginError)
  });

  const code = queryString.parse(window.location.search)?.code;
  useEffect(() => {
    if (code && !inApp) {
      setCleverSubmitting(true);
      setShowLoginInput?.(true);
      justFetch(endpoints.cleverLogin, 'POST', { code })
        .then(res => {
          setCleverSubmitting(false);
          if (!res.ok) {
            res.json().then(body => setSubmitError(body.message || body.error));
            setInvalidCredentials(false);

            return;
          }

          setCleverSuccess(true);
          return res
            .json()
            .then((body: { type: 'teacher', session_token: string, access_token: string | null, user: IUserProfile } | { type: 'student', student_code: string }) => {
              if (body.type === 'teacher') {
                const session = body;

                setSessionTokenCookie(session.session_token);

                try {
                  mutate(endpoints.session, session);
                } catch (e) { }

                setLoginMode?.('teacher');
                handleRedirectAfterLogin(session);
              } else {
                window.location.href = process.env.REACT_APP_GAME_ENDPOINT! + `/play?user=${body.student_code}`;
              }
            })
        })
        .catch(handleUnexpectedKodableLoginError);
    }
  }, [code, inApp])

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid',
    onSuccess: async codeResponse => {
      justFetch(endpoints.socialLogin, 'POST', {
        service: 'google',
        auth_code: codeResponse?.code,
        client_id: '174894017176-0agntgejqqasph3ptcnoclvhahe00pv9.apps.googleusercontent.com',
        source: window.location.href,
        import_student_code: import_student_code,
        redirect_uri: window.location.protocol + '//' + window.location.host
      })
      .then(handleKodableLoginResponse)
      .catch(handleUnexpectedKodableLoginError)
    },
    onError: () => {
      alert.error("Login Failed", "Please try again later.")
    }
  });

  const addAppleIdEventListener = useAppleId(AppleID => {
    document.addEventListener('AppleIDSignInOnSuccess', onAppleLoginSuccess);
    AppleID.auth?.renderButton();
  });

  useEffect(() => { addAppleIdEventListener() }, []);

  const onAppleLoginSuccess = ({ detail: { authorization: { id_token } } }: any) => {
    if (appleLoggedIn) {
      return;
    }

    appleLoggedIn = true;

    return justFetch(endpoints.socialLogin, 'POST', {
      service: 'apple',

      source: window.location.href,

      id_token: id_token,

      import_student_code: import_student_code
    })
      .then(handleKodableLoginResponse)
      .catch(handleUnexpectedKodableLoginError)
  }

  const handleKodableLoginResponse = async (res: Response) => {
    if (!res.ok) {
      setShowLoginInput?.(true);
      if (res.status === 401) {
        setSubmitError(false);
        setInvalidCredentials(true);
      } else {
        res.json().then(body => setSubmitError(body.message || body.error));
        setInvalidCredentials(false);
      }

      return;
    }

    return res
      .json()
      .then((session: ISession) => {
        setSessionTokenCookie(session.session_token, false, session?.access_token);
        try {
          mutate(endpoints.session, session);
        } catch (e) { }
        handleRedirectAfterLogin(session);
      }).catch((error) => {
        setShowLoginInput?.(true);
        setSubmitError('Error getting ISession: ' + error);
        setInvalidCredentials(false);
      });
  }

  const handleUnexpectedKodableLoginError = (e: any) => {

    setShowLoginInput?.(true);
    setCleverSubmitting(false);
    setSubmitError('An unknown error occurred.');
    setInvalidCredentials(false);
  }

  if (cleverSubmitting) {
    return <Container inApp={inApp} showProgress={false}>
      <Box height={300} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size="3rem" color="primary" />
      </Box>
    </Container>
  }

  if (cleverSuccess) {
    return <Container inApp={inApp} showProgress={false}>
      <Box height={300} display="flex" alignItems="center" justifyContent="center">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.1 }}
        >
          <FontAwesomeIcon color={theme.palette.blue.main} size="3x" icon={faCheckCircle} />
        </motion.div>
      </Box>
    </Container>
  }

  const Field = inApp ? TextField : CssTextField;

  return <Container inApp={inApp} showProgress={form.isSubmitting}>
    <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" width="100%">
      <ForgotPasswordDialog email={form.values.username} {...forgotPasswordDialogState} />
      <LoginTroubleDialog {...loginTroubleDialogState} />
      <Box width="100%">
        <form onSubmit={form.handleSubmit} className={sharedClasses.vspacing2}>
          {!inApp && <>
            <Typography style={{ textTransform: 'uppercase', color: 'white', fontSize: 18, alignSelf: 'start', fontWeight: 'bold' }}>Email Address</Typography>
          </>}
          <Field
            id="username"
            name="username"
            label={inApp ? "Email Address" : undefined}
            placeholder="email@domain.com"
            onChange={form.handleChange}
            value={form.values.username}
            error={form.submitCount > 0 && !!form.errors.username}
            helperText={form.submitCount > 0 ? form.errors.username : ''}
            disabled={form.isSubmitting}
            InputProps={inApp ? {
              classes: {
                root: loginClasses.loginInput
              }
            } : undefined}
          />
          {!inApp && <>
            <Typography style={{ textTransform: 'uppercase', color: 'white', fontSize: 18, alignSelf: 'start', fontWeight: 'bold', marginTop: 32 }}>Password</Typography>
          </>}
          <Field
            id="password"
            name="password"
            type="password"
            placeholder="password"
            label={inApp ? "Password" : undefined}
            onChange={form.handleChange}
            value={form.values.password}
            error={form.submitCount > 0 && !!form.errors.password}
            helperText={form.submitCount > 0 ? form.errors.password : ''}
            disabled={form.isSubmitting}
            InputProps={inApp ? {
              classes: {
                root: loginClasses.loginInput
              }
            } : undefined}
          />
          {inApp && <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              onClick={forgotPasswordDialogState.handleOpen}
              size="small"
            >
              Forgot password?
            </Button>
          </Box>}
          {!inApp && <Box display="flex" justifyContent="space-between" color="white" alignItems="center" fontSize={17}>
            <Typography style={{ color: 'white', fontSize: 17 }}>
              Don't have an account yet? <Link
                style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Source Sans Pro, Open Sans, sans-serif' }}
                href={`${process.env.REACT_APP_KODABLE_WEBSITE}/register`}
              >Register now!</Link>
            </Typography>

            <Link
              style={{ color: 'white', fontWeight: 'bold', fontFamily: 'Source Sans Pro, Open Sans, sans-serif' }}
              href="#"
              onClick={forgotPasswordDialogState.handleOpen}
            >Forgot password?</Link>
          </Box>}
          {
            !!submitError && <Alert
              severity="error"
            >{submitError} <Link href="" onClick={e => { e.preventDefault(); loginTroubleDialogState.handleOpen(); }}>Click here for troubleshooting steps</Link></Alert>
          }

          {
            invalidCredentials && <Alert
              severity="error"
            >We couldn't log you in with those credentials.</Alert>
          }
          <Box display="flex" justifyContent="center" className={sharedClasses.hspacing2}>
            {inApp && <Button
              variant="contained"
              color="blue"
              type="submit"
              disabled={form.isSubmitting}
              className={loginClasses.loginButton}
            >Log In</Button>}
            {!inApp && <CssButton
              variant="outlined"
              color="blue"
              className={uLoginClasses.primaryButton}
              style={{
                marginTop: 40,
                marginBottom: 40
              }}
              type="submit"
              disabled={form.isSubmitting}
            >Log In</CssButton>}
          </Box>
        </form>
      </Box>

      {<Box
        my={2}
        width={300}
        borderBottom={`1px solid rgba(0,0,0,0.12)`}
        display={inApp ? 'block' : { xs: 'block', sm: 'none' }}
      ></Box>}

      <Box width={inApp ? 300 : undefined} display="flex" flexDirection={inApp ? 'column' : 'row'} alignItems="stretch">
        <Button
          startIcon={<FontAwesomeIcon icon={faGoogle} />}
          className={loginClasses.loginButton}
          variant="contained"
          onClick={() => handleGoogleLogin()}
          color="red"
        >Sign in with Google</Button>
        <div
          id="appleid-signin"
          className={classNames('login-button', loginClasses.loginButton)}
          data-color="black"
          data-border="true"
          data-type="sign-in"
          style={{ margin: inApp ? '20px auto' : '0 20px', cursor: 'pointer' }}
        ></div>
        {mode === 'teacher' && <CleverLoginButton />}
      </Box>

      {inApp && <Box mt={2} width={300} className={sharedClasses.vspacing2}>
        <Divider />

        <Typography align="center" variant="body2">
          Don't have a Kodable account? Get started for free:
        </Typography>

        <Button
          color="secondary"
          variant="outlined"
          className={loginClasses.loginButton}
          href={`${process.env.REACT_APP_KODABLE_WEBSITE}/register`}
        >
          Register
        </Button>
      </Box>}
    </Box>
  </Container>
}

export const CleverLoginButton: React.VFC = () => {
  const loginClasses = useLoginStyles();

  const cleverLoginButtonLink = () =>
  {
    const redirectURL = `${process.env.REACT_APP_CLEVER_REDIRECT_URL}`
    const encodedredirectURL = encodeURIComponent(redirectURL);
    return `${process.env.REACT_APP_CLEVER_AUTH_URL}&redirect_uri=${encodedredirectURL}`
  }

  return <Button
    startIcon={<img style={{ height: '1.5rem' }} src={'/images/CleverLogo.png'} />}
    className={loginClasses.loginButton}
    variant="contained"
    color="cleverBlue"
    {...{
      component: 'a',
      href: cleverLoginButtonLink()
    }}
  >Sign in with Clever</Button>;
}

const Container: React.FC<{ inApp: boolean, showProgress: boolean }> = ({ inApp = false, showProgress = false, children }) => {
  const loginClasses = useLoginStyles();

  if (inApp) {
    return <PageContainer
      variant="centered"
      showProgress={showProgress}
      classes={{
        wrapper: loginClasses.wrapper,
        contentCentered: loginClasses.contentCentered
      }}
    >
      <Box display="flex" justifyContent="center" mb={2}>
        <img
          style={{
            height: 60
          }}
          src="/images/KodableLogo.png"
          alt="Kodable Logo"
        ></img>
      </Box>
      {children}
    </PageContainer>
  }

  return <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center">{children}</Box>
}

export default LoginInput;