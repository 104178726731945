import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useDialogState from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import { mutate } from "swr";
import { ITeacher } from "types/ITeacher";
import { ILicense } from "types/ILicense";
import * as Yup from 'yup';
import { ITeacherData } from "types/ITeacherData";

interface AssignLicenseDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any, setError: React.Dispatch<React.SetStateAction<string | null>>) => void;
  initialValues: { school_id: number; site_name: string };
}

const AssignLicenseDialog: React.FC<AssignLicenseDialogProps> = ({ open, onClose, onSubmit, initialValues }) => {
  const sharedClasses = useSharedStyles();
  const assignLicenseForm = useFormik({
    initialValues: {
      school_id: initialValues.school_id,
      site_name: initialValues.site_name,
      email: '',
    confirmEmail: ''
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Enter a valid email address.')
        .required("Please enter the email address of the teacher to whom you'd like to assign a license."),
      confirmEmail: Yup
        .string()
        .required('Please confirm the email address above.')
        .equals([Yup.ref('email')], "The email you entered doesn't match the one you entered above.")
    }),
    onSubmit: values => {
      onSubmit(values, (error)=>
      {
        setAssignLicenseSubmitError(error);
        assignLicenseForm.setSubmitting(false);
      }
      );
    }
  });
  const [assignLicenseSubmitError, setAssignLicenseSubmitError] = useState<string | null>(null);
  useEffect(() => {
    if (open) {
      setAssignLicenseSubmitError(null);
      assignLicenseForm.resetForm({
        values: {
          school_id: initialValues.school_id,
          site_name: initialValues.site_name,
          email: '',
          confirmEmail: ''
        }
      });
    }
  }, [open, initialValues]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <LinearProgress style={{ visibility: assignLicenseForm.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
      <form onSubmit={assignLicenseForm.handleSubmit}>
        <DialogTitle>Assign License</DialogTitle>
        <DialogContent className={sharedClasses.vspacing2}>
          <Typography>
            This will add the person below as an admin to your subscription for selected site. They will be able to invite other teachers to be part of their school license.
          </Typography>

          <TextField
            id="site_name"
            name="site_name"
            label="Site Name"
            value={assignLicenseForm.values.site_name}
            onChange={assignLicenseForm.handleChange}
            error={assignLicenseForm.submitCount > 0 && !!assignLicenseForm.errors.email}
            helperText={assignLicenseForm.submitCount > 0 ? assignLicenseForm.errors.email : undefined}
            disabled={true}
            onBlur={assignLicenseForm.handleBlur}
          />

          <TextField
            id="email"
            name="email"
            label="Email"
            value={assignLicenseForm.values.email}
            onChange={assignLicenseForm.handleChange}
            error={assignLicenseForm.submitCount > 0 && !!assignLicenseForm.errors.email}
            helperText={assignLicenseForm.submitCount > 0 ? assignLicenseForm.errors.email : undefined}
            disabled={assignLicenseForm.isSubmitting}
            onBlur={assignLicenseForm.handleBlur}
          />

          <TextField
            id="confirmEmail"
            name="confirmEmail"
            label="Confirm Email"
            value={assignLicenseForm.values.confirmEmail}
            onChange={assignLicenseForm.handleChange}
            error={assignLicenseForm.submitCount > 0 && !!assignLicenseForm.errors.confirmEmail}
            helperText={assignLicenseForm.submitCount > 0 ? assignLicenseForm.errors.confirmEmail : undefined}
            disabled={assignLicenseForm.isSubmitting}
            onBlur={assignLicenseForm.handleBlur}
          />

          {assignLicenseSubmitError !== null && <Alert severity="error">{assignLicenseSubmitError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={assignLicenseForm.isSubmitting}
          >Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={assignLicenseForm.isSubmitting}
          >Assign</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface UnassignLicenseDialogProps {
  open: boolean;
  onClose: () => void;
  teacherBeingUnassigned?: ITeacher;
  siteBeingUnassigned?: string;
  onUnassign: () => void;
  unassignLicenseError: boolean;
}

const UnassignLicenseDialog: React.FC<UnassignLicenseDialogProps> = ({ open, onClose, teacherBeingUnassigned, siteBeingUnassigned, onUnassign, unassignLicenseError }) => {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Unassign License?</DialogTitle>
      <DialogContent>
        <Typography>
          This will remove the person below as an admin to your subscription for the selected site.
        </Typography>
        <Typography>
          {"\u00A0"}
        </Typography>
        <TextField
          id="site_name"
          label="Site Name"
          value={siteBeingUnassigned}
          disabled={true}
        />
        <TextField
          id="email"
          label="Email"
          value={teacherBeingUnassigned?.username}
          disabled={true}
        />
        <Typography>
          {"\u00A0"}
        </Typography>
        <Typography>
          {teacherBeingUnassigned?.name} ({teacherBeingUnassigned?.username}) and the teachers connected to their account will no longer have access to your subscription.
        </Typography>

        {unassignLicenseError && <Alert severity="error">There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onUnassign}
        >Unassign</Button>
      </DialogActions>
    </Dialog>
  );
};

const LicensesTable: React.VFC = () => {
  const sharedClasses = useSharedStyles();

  const { teacherData, isValidating } = useTeacherInit();

  const assignLicenseDialogState = useDialogState();
  const unassignLicenseDialogState = useDialogState();

  const [teacherBeingUnassigned, setTeacherBeingUnassigned] = useState<ITeacher>();
  const [siteBeingUnassigned, setSiteBeingUnassigned] = useState<string>();
  const [unassignLicenseError, setUnassignLicenseError] = useState(false);
  
  const [initialValues, setInitialValues] = useState({ school_id: 0, site_name: '' });

  useEffect(() => {
    if (teacherBeingUnassigned) {
      setUnassignLicenseError(false);
    }
  }, [teacherBeingUnassigned])

  const handleAssignLicense = (values: any, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
    setError(null);
    const body = {
      email: values.email,
      school_id: values.school_id,
      site_name: values.site_name,
      subscription_id: teacherData!.subscription.id,
      secret_key: teacherData!.subscription.secret_key,
      send_mail: true
    };
    console.log("BODY: ", body);
    return justFetch(endpoints.assignLicense(teacherData!.subscription.purchase_process_id), 'POST', body)
      .then(async res => {
        if (!res.ok) {
          const error = await res.json();
          throw new Error(error?.message || 'An unknown error occurred.');
        }

        mutate(endpoints.teacherInit);
        assignLicenseDialogState.onClose();
      })
      .catch((error) => {
        setError(error?.message);
      });
  };

  const handleUnassignLicense = () => {
    justFetch(endpoints.unassignLicense(teacherData!.subscription.purchase_process_id), 'PUT', {
      subscription_id: teacherData!.subscription.id,
      teacher_id: teacherBeingUnassigned!.id
    })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        mutate(endpoints.teacherInit);
        setTeacherBeingUnassigned(undefined);
        setSiteBeingUnassigned(undefined);
        unassignLicenseDialogState.onClose();
      })
      .catch(() => setUnassignLicenseError(true));
  };

  const licenseTeacher = (teacher_id: number) => {
    return teacherData?.teachers.find(teacher => teacher.id === teacher_id);
  };

  const assignedLicenses = () => {
    return licenses.filter(license => license.teacher_id);
  };

  if (!teacherData) {
    return <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  }
  const licenses = teacherData?.subscription.licenses;

  return <>
    <AssignLicenseDialog 
      open={assignLicenseDialogState.open}
      onClose={assignLicenseDialogState.onClose}
      onSubmit={handleAssignLicense}
      initialValues={initialValues}
    />
    <UnassignLicenseDialog 
      open={unassignLicenseDialogState.open}
      onClose={unassignLicenseDialogState.onClose}
      teacherBeingUnassigned={teacherBeingUnassigned}
      siteBeingUnassigned={siteBeingUnassigned}
      onUnassign={handleUnassignLicense}
      unassignLicenseError={unassignLicenseError}
    />
    <Box pb={2} className={sharedClasses.hspacing2}>
      <Chip
        label={`${assignedLicenses().length}/${teacherData?.subscription.licenses.length} licenses assigned`}
      />
    </Box>

    {isValidating && <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}

    {!isValidating && <TableContainer component={Paper} {...{ variant: 'outlined' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              School
            </TableCell>
            <TableCell>
              Admin
            </TableCell>
            <TableCell>
              Email
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {licenses?.map(license => (
            <TableRow key={license.teacher_id}>
              <TableCell>
                {license.site_name || 'None selected'}
              </TableCell>
              <TableCell>
                {licenseTeacher(license.teacher_id)?.name}
              </TableCell>
              <TableCell>
                {licenseTeacher(license.teacher_id)?.username}
              </TableCell>
              <TableCell style={{ width: 0 }}>
                {license.teacher_id ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setTeacherBeingUnassigned(licenseTeacher(license.teacher_id)!);
                      setSiteBeingUnassigned(license.site_name);
                      unassignLicenseDialogState.handleOpen();
                    }}
                  >
                    Unassign
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      console.log("License: ", license);
//                      assignLicenseForm.values.school_id = license.school_id;
//                      assignLicenseForm.values.site_name = license.site_name;
                      setInitialValues({ school_id: license.school_id, site_name: license.site_name });
                      assignLicenseDialogState.handleOpen();
                    }}
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    Assign
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    {false && <Alert severity="info">No licenses have been assigned yet.</Alert>}
  </>
}

export default LicensesTable;
export { AssignLicenseDialog, UnassignLicenseDialog };
