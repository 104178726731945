interface IGradeDescription {
  name: string;
  shortName: string;
  order: number;
  color?: string;
}

const grades: { [key in 'all' | 'k' | '1' | '2' | '3' | '4' | '5']: IGradeDescription} = {
  all: {
    name: '6+',
    shortName: '6+',
    order: 6,
    color: '#000000'
  },
  k: {
    name: "Kindergarten",
    shortName: "K",
    order: 0,
    color: '#4791c9'
  },
  '1': {
    name: '1st Grade',
    shortName: '1st',
    order: 1,
    color: '#c92c63'
  },
  '2': {
    name: '2nd Grade',
    shortName: '2nd',
    order: 2,
    color: '#4a2275'
  },
  '3': {
    name: '3rd Grade',
    shortName: '3rd',
    order: 3,
    color: '#df9c4a'
  },
  '4': {
    name: '4th Grade',
    shortName: '4th',
    order: 4,
    color: '#90b84a'
  },
  '5': {
    name: '5th Grade',
    shortName: '5th',
    order: 5,
    color: '#c33e52'
  }
}

export const gradesArray = Object.keys(grades)
  .map((gradeKey) => ({ ...grades[gradeKey as keyof typeof grades], key: gradeKey as keyof typeof grades }))
  .sort(({ order: A }, { order: B }) => A - B)

export default grades;