import { useLocation } from "react-router-dom";

export const routes = {
  login: '/login',
  learningTools: '/parent/learning-tools',
  videos: '/videos',
  parentVideos: '/parent/videos',
  settings: {
    index: '/settings',
    billing: '/settings/billing',
    updateBilling: '/settings/update-billing',
    updateSchoolSearch: '/settings/update-school/search',
    updateSchoolResults: '/settings/update-school/results',
    updateSchoolSuccess: '/settings/update-school/success',
  }, 
  parentConnect: '/parent-connect',
  classes: {
    index: '/classes/list',
    addNew: {
      index: '/classes/add',
      details: '/classes/add/details',
      students: '/classes/add/students',
      code: '/classes/add/code',
      parentLetters: '/classes/add/parent-letters'
    },
    bulkAdd: '/classes/bulk-add',
    import: {
      clever: '/classes/import/clever',
      google: '/classes/import/google'
    }
  },
  class: {
    index: (classId: string | number = ':classId') => `/classes/${classId}`,
    courses: (classId: string = ':classId') => `/classes/${classId}/courses`,
    students: (classId: string = ':classId', prefix = `/classes/${classId}`) => `${prefix}/students`,
    studentWork: (classId: string = ':classId', prefix = `/classes/${classId}`) => `${prefix}/student-work`,
    courseInterstitial: (classId: string | number = ':classId', prefix = `/classes/${classId}`) => `${prefix}/course-info`,
    parentLettersInterstitial: (classId: string = ':classId', prefix = `/classes/${classId}`) => `${prefix}/parent-letters`,
    progress: (classId: string | number = ':classId', courseId: string | number = ':courseId?', prefix = `/classes/${classId}`) => `${prefix}/progress/${courseId}`
  },
  students: {
    index: '/students/list',
    viewStudent: (studentId: string | number = ':studentId') => `/parent/students/${studentId}`
  },
  handouts: {
    studentPasswordCard: (classId: string | number, device: 'ipad' | 'computer') => `student-password-card/klass/${device}/${classId}`,
    parentInstructions: (classId: string | number, language: 'english' | 'spanish') => `parent-instructions/${classId}/${language}`,
    printClassFuzzes: (classId: string | number) => `creative/fuzz/class/${classId}`,
    printClassMazes: (classId: string | number) => `creative/maze/class/${classId}`,
    printSingleFuzz: (studentId: string | number, fuzzId: number) => `creative/fuzz/student/${studentId}?fuzzId=${fuzzId}`,
    printSingleMaze: (studentId: string | number, mazeId: string) => `creative/maze/student/${studentId}?mazeId=${mazeId}`,
    hocCertificate: (classId: string | number = ':classId') => `/handouts/hoc-certificate/${classId}`,
    parentLetters: (classId: string | number = ':classId') => `/handouts/parent-letters/${classId}`,
    valentinesHandouts: (classId: string | number = ':classId', focusedStudentId?: string | number) => `/handouts/valentines-parent-letters/${classId}${focusedStudentId ? `?focusedStudentId=${focusedStudentId}` : ''}`,
    studentHandouts: (classId: string | number = ':classId') => `/handouts/student-handouts/${classId}` 
  },
  demo: {
    index: '/demo'
  },
  curriculum: {
    lesson: (unitId: string | number = ':unitId', lessonId: string | number = ':lessonId') => `/curriculum/lesson/${unitId}/${lessonId}`,
  },
  courses: {
    index: '/courses',
    viewCourse: {
      index: (courseId: number | string = ':courseId', prefix = `/courses/${courseId}`) => `${prefix}`,
      materials: (courseId: number | string = ':courseId', prefix = `/courses/${courseId}`) => `${prefix}/materials`,
      standards: (courseId: number | string = ':courseId', prefix = `/courses/${courseId}`) => `${prefix}/standards`,
    }
  },
  admin: {
    index: `/admin`,
    licenses: (prefix = '/admin') => `${prefix}/licenses`
  },
  cleverSync: '/sync_clever',
  onboarding: {
    index: `/parent/onboarding`,
    plans: `/parent/onboarding/plans`,
    intro: `/parent/onboarding/intro`,
    playToLearn: `/parent/onboarding/play-to-learn`
  },
  password: {
    reset: `/password-reset`,
    resetToken: `/reset`
  },
  upgrade: {
    index: `/parent/upgrade`,
    info: `/parent/upgrade/info`,
  },
  parent: {
    upgrade: {
      success: `/parent/upgrade/success`
    }
  },
  reports: {
    index: `/reports`,
    multiClass: `/reports/classes`,
    school: `/reports/school`
  },
  sales: {
    index: `/sales`,
    orders: {
      index: `/sales/orders`,
      submitWithQuote: (quoteSecretCode: string) => `/sales/orders/submit/plan?quote=${quoteSecretCode}`,
      submitWithTargetTeacherId: (teacherId: string | number) => `/sales/orders/submit/plan?renew=${teacherId}`,
      submit: {
        index: `/sales/orders/submit`,
        selectLocation: `/sales/orders/submit/select-location`,
        selectOrgType: `/sales/orders/submit/select-organization-type`,
        findSchool: `/sales/orders/submit/find-school`,
        findDistrict: `/sales/orders/submit/find-district`,
        plan: `/sales/orders/submit/plan`,
        planWithTeacher: (teacherName: string, teacherUsername: string) => `/sales/orders/submit/plan?upgrade_teacher_name=${encodeURIComponent(teacherName)}&upgrade_teacher_username=${encodeURIComponent(teacherUsername)}`,
        billingContact: `/sales/orders/submit/billing-contact`,
        administrator: `/sales/orders/submit/administrator`,
        payment: `/sales/orders/submit/payment`,
        confirmation: `/sales/orders/submit/confirmation`,
        purchaseOrder: `/sales/orders/submit/purchase-order`,
        success: `/sales/orders/submit/success`
      }
    },
    quotes: {
      index: `/sales/quotes`,
      create: {
        new: `/sales/quotes/new`,
        selectLocation: `/sales/quotes/new/select-location`,
        selectOrgType: `/sales/quotes/new/select-organization-type`,
        findSchool: `/sales/quotes/new/find-school`,
        findDistrict: `/sales/quotes/new/find-district`,
        form: `/sales/quotes/new/form`,
      },
      renewal: (subscription_id?: number) => `/sales/quotes/renewal${subscription_id ? `?subscription_id=${subscription_id}` : ''}`,
      createRenewal: (teacherId: string | number = ':teacherId') =>  `/sales/quotes/renew/${teacherId}`,
      view: (secret_code: string = ':secret_code') => `/sales/quotes/view/${secret_code}`,
      editStart: (secret_code: string = ':secret_code') => `/sales/quotes/edit_start/${secret_code}`,
      edit: (secret_code: string = ':secret_code') => `/sales/quotes/edit/${secret_code}`
    }
  },
  valentines: {
    preview: `/valentines/preview`,
    handout: (classId: string | number = ':classId') => `/valentines/promo-handout/${classId}`,
    finder: (secretCode: string = ':secret_code') => `/valentines/class/${secretCode}`,
    index: `/valentines`,
    parentPrintable: (studentId: string | number = ':studentId') => `/valentines/print-student/${studentId}`,
    viewClass: (classId: string | number = ':classId') => `/valentines/view-class/${classId}`
  },
  godmode: {
    index: `/godmode`,
    ambassadors: `/godmode/ambassadors`,
    sales: {
      index: `/godmode/sales`,
      orders: `/godmode/sales/orders`,
      quotes: `/godmode/sales/quotes`,
      accountSetup: (orderId: string | number = ':orderId') => `/godmode/sales/account-setup/${orderId}`
    },
    subscriptions: {
      view: (subscriptionId: string | number = ':subscriptionId') => `/godmode/subscriptions/${subscriptionId}`,
    },
    organizationProfiles: {
      index: `/godmode/organization-profiles`,
      view: (organizationProfileId: string | number = ':organizationProfileId') => `/godmode/organization-profiles/${organizationProfileId}`
    },
    userLookup: `/godmode/user-lookup`,
    students: {
      index: `/godmode/students`,
      view: (studentId: string | number = ':studentId') => `/godmode/students/${studentId}`
    },
    user: {
      index: (teacherId: number | string = ':teacherId') => `/godmode/teacher/${teacherId}`,
      account: (teacherId: number | string = ':teacherId', prefix = `/godmode/teacher/${teacherId}`) => `${prefix}/account`,
      subscription: (teacherId: number | string = ':teacherId', prefix = `/godmode/teacher/${teacherId}`) => `${prefix}/subscription`,
    },
    events: {
      index: '/godmode/events'
    },
    schools: {
      index: '/godmode/schools',
      schoolRequests: '/godmode/schools/requests',
      schoolAdd: '/godmode/schools/add',
      schoolEdit: (schoolId: string | number = ':schoolId') => `/godmode/schools/edit/${schoolId}`,
    },
    resources: {
      index: '/godmode/resources'
    },
    revenue: {
      index: '/godmode/revenue'
    },
    cleverDistricts: '/godmode/clever-districts',
    giftCodes: '/godmode/gift-codes',
  },
  teacherOnboarding: {
    index: '/onboarding',
    addSchool: {
      index: '/onboarding/add-school',
      results: '/onboarding/add-school/results'
    },
    addClassManual: {
      index: '/onboarding/add-class',
      students: '/onboarding/add-class/students',
    },
    assignGames: {
      index: '/onboarding/assign-games'
    },
    inviteParents: {
      index: '/onboarding/invite-parents'
    },
    addClassClever: {
      index: '/onboarding/import-class'
    }
  },
  parentOnboarding: {
    index: '/parent/onboarding',
    addPlayer: '/parent/onboarding/add-player',
    linkPlayer: '/parent/onboarding/link-player',
    intro: '/parent/onboarding/intro',
    learning: '/parent/onboarding/learning',
    plans: '/parent/onboarding/plans',
    progress: '/parent/onboarding/progress',
    progressLinked: '/parent/onboarding/progress-linked',
    progressUpgrade: '/parent/onboarding/progress-upgrade',
    playToLearn: '/parent/onboarding/play-to-learn',
  },
  promo: {
    backToSchool: '/back-to-school'
  },
  hoc: {
    index: '/hour-of-code'
  },
  referral: {
    landing: '/events/referral',
  }
} as const;

export const publicRoutes = [
  routes.password.resetToken,
  routes.sales.quotes.index,
  routes.sales.orders.index,
  '/valentines/promo-handout',
  '/valentines/class',
  '/curriculum/lesson/'
]

export const isPublicRoute = (location: ReturnType<typeof useLocation>) => publicRoutes.some(publicRoute => location.pathname.startsWith(publicRoute));